// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-ch-pages-404-js": () => import("./../../../src/CH/pages/404.js" /* webpackChunkName: "component---src-ch-pages-404-js" */),
  "component---src-ch-pages-about-us-js": () => import("./../../../src/CH/pages/about-us.js" /* webpackChunkName: "component---src-ch-pages-about-us-js" */),
  "component---src-ch-pages-blog-js": () => import("./../../../src/CH/pages/blog.js" /* webpackChunkName: "component---src-ch-pages-blog-js" */),
  "component---src-ch-pages-contact-js": () => import("./../../../src/CH/pages/contact.js" /* webpackChunkName: "component---src-ch-pages-contact-js" */),
  "component---src-ch-pages-index-js": () => import("./../../../src/CH/pages/index.js" /* webpackChunkName: "component---src-ch-pages-index-js" */),
  "component---src-ch-pages-ppc-js": () => import("./../../../src/CH/pages/ppc.js" /* webpackChunkName: "component---src-ch-pages-ppc-js" */),
  "component---src-ch-pages-privacy-policy-js": () => import("./../../../src/CH/pages/privacy-policy.js" /* webpackChunkName: "component---src-ch-pages-privacy-policy-js" */),
  "component---src-ch-pages-redirect-js": () => import("./../../../src/CH/pages/redirect.js" /* webpackChunkName: "component---src-ch-pages-redirect-js" */),
  "component---src-ch-pages-terms-of-service-js": () => import("./../../../src/CH/pages/terms-of-service.js" /* webpackChunkName: "component---src-ch-pages-terms-of-service-js" */),
  "component---src-ch-templates-blog-js": () => import("./../../../src/CH/templates/Blog.js" /* webpackChunkName: "component---src-ch-templates-blog-js" */),
  "component---src-ch-templates-region-js": () => import("./../../../src/CH/templates/Region.js" /* webpackChunkName: "component---src-ch-templates-region-js" */),
  "component---src-ch-templates-theme-js": () => import("./../../../src/CH/templates/Theme.js" /* webpackChunkName: "component---src-ch-templates-theme-js" */)
}

